<template>
  <div id="print" ref="print">
    <v-container class="text-center pa-0">
      <div class="row">
        <div class="col-12 col-sm-12">
          <v-card
            :loading="loading"
            class="mx-auto"
            :max-width="registered == true ? 420 : 800"
          >
            <v-system-bar height="47" color="primary">
              <h3 class="white--text">
                {{ !empty(formEvent) ? formEvent.name : "EVENT DETAILS" }}
              </h3>
              <v-spacer></v-spacer>
              <v-btn
                title
                color="warning"
                class="mr-0"
                elevation="0"
                @click="back_to_profile"
              >
                BACK TO EVENTS
                <v-icon color="white">mdi-exit-to-app</v-icon>
              </v-btn>
            </v-system-bar>
            <v-card-text class="pa-2" v-if="!empty(formEvent)">
              <div class="row">
                <div class="col-12">
                  <image-field
                    size="mini"
                    :disabled="true"
                    :show-zoom="false"
                    :image-click="true"
                    height="100"
                    :value="formEvent.logo_path"
                  />
                </div>
              </div>
              <v-card-text>
                <div>
                  {{ formEvent.intro_text }}
                </div>
              </v-card-text>
              <v-divider class="mx-4"></v-divider>
              <v-card-title class="text-center">
                <div style="width: 100%">
                  {{ formatDate(formEvent.from_date, "MMM DD, yyyy") }}
                  {{ " to " }}
                  {{ formatDate(formEvent.to_date, "MMM DD, yyyy") }}
                </div>
              </v-card-title>
              <v-divider class="mx-4"></v-divider>
            </v-card-text>
            <v-card-text class="pa-2">
              <el-form
                :model="formEvent"
                v-if="!empty(formEvent.exhibitor)"
                :rules="rules"
                ref="form"
                label-width="120px"
                label-position="top"
              >
                <v-card
                  :loading="loading"
                  class="mx-auto ma-2"
                  :elevation="0"
                  outlined
                >
                  <v-system-bar height="47" color="secondary">
                    <h3 class="white--text">EXHIBITOR DETAILS</h3>
                    <v-spacer></v-spacer>
                  </v-system-bar>
                  <v-card-text>
                    <div class="row hidden-md-and-up">
                      <div
                        class="col-lg-12 col-sm-12 col-xs-12"
                        style="height: 200px"
                      >
                        <el-form-item label="Logo" prop="logo_path">
                          <image-field
                            size="mini"
                            :disabled="true"
                            :show-zoom="false"
                            :image-click="true"
                            width="0"
                            height="50"
                            :value="formEvent.exhibitor_logo"
                          />
                        </el-form-item>
                        <el-form-item label="Image" prop="image_path">
                          <image-field
                            size="mini"
                            :disabled="true"
                            :show-zoom="false"
                            :image-click="true"
                            height="100"
                            :value="formEvent.exhibitor_image"
                          />
                        </el-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-sm-12">
                        <div class="row">
                          <div
                            class="
                              col-lg-3 col-sm-12 col-xs-12
                              hidden-sm-and-down
                            "
                          >
                            <el-form-item label="Logo" prop="logo_path">
                              <image-field
                                size="mini"
                                :disabled="true"
                                :show-zoom="false"
                                :image-click="true"
                                width="0"
                                height="50"
                                :value="formEvent.exhibitor_logo"
                              />
                            </el-form-item>
                            <el-form-item label="Image" prop="image_path">
                              <image-field
                                size="mini"
                                :disabled="true"
                                :show-zoom="false"
                                :image-click="true"
                                height="100"
                                :value="formEvent.exhibitor_image"
                              />
                            </el-form-item>
                          </div>
                          <div class="col-lg-9 col-sm-12 col-xs-12">
                            <div class="row">
                              <div class="col-lg-6 col-sm-12">
                                <el-form-item label="English Name" prop="name">
                                  <el-input
                                    size="mini"
                                    :disabled="true"
                                    v-model="formEvent.exhibitor"
                                  ></el-input>
                                </el-form-item>
                              </div>
                              <div class="col-lg-6 col-sm-12">
                                <el-form-item
                                  label="Requested Stall"
                                  prop="stall_no"
                                >
                                  <el-input
                                    size="mini"
                                    v-model="formEvent.exhibitor_stall_no"
                                    :disabled="true"
                                  ></el-input>
                                </el-form-item>
                              </div>
                              <div class="col-lg-12 col-sm-12">
                                <el-form-item label="Address" prop="address">
                                  <el-input
                                    size="mini"
                                    type="textarea"
                                    :disabled="true"
                                    :rows="4"
                                    v-model="formEvent.exhibitor_address"
                                  ></el-input>
                                </el-form-item>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-12">
                        <el-form-item label="Tel" prop="tel">
                          <el-input
                            size="mini"
                            v-model="formEvent.exhibitor_tel"
                            :disabled="true"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-lg-3 col-sm-12">
                        <el-form-item label="Mobile" prop="mobile">
                          <el-input
                            size="mini"
                            v-model="formEvent.exhibitor_mobile"
                            :disabled="true"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-lg-6 col-sm-12">
                        <el-form-item label="Email" prop="email">
                          <el-input
                            size="mini"
                            v-model="formEvent.exhibitor_email"
                            :disabled="true"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-sm-12">
                        <el-form-item label="Profile" prop="profile">
                          <html-editor
                            v-model="formEvent.exhibitor_profile"
                            :disabled="true"
                          />
                        </el-form-item>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
                <v-card
                  :loading="loading"
                  class="mx-auto ma-2"
                  :elevation="0"
                  outlined
                >
                  <v-system-bar height="47" color="secondary">
                    <h3 class="white--text">USERS</h3>
                    <v-spacer></v-spacer>
                    <select-list
                      v-model="exhibitor_users_id"
                      :list="users"
                      @change="addUserFromList"
                      class="mr-2"
                      palceholder="Select User to add"
                    />
                    <v-btn
                      title
                      color="warning"
                      @click="newUser"
                      class="mr-2 hidden-sm-and-down"
                      elevation="0"
                    >
                      <v-icon color="white">mdi-plus</v-icon>
                      CREATE NEW USER
                    </v-btn>
                  </v-system-bar>
                  <v-card-text>
                    <div class="row">
                      <div class="col-lg-12 col-sm-12">
                        <el-table
                          :data="formEvent.event_users"
                          style="width: 100%"
                        >
                          <el-table-column
                            prop="name"
                            width="150"
                            label="User Name"
                            :show-overflow-tooltip="true"
                          ></el-table-column>
                          <el-table-column
                            prop="email"
                            label="Email"
                            :show-overflow-tooltip="true"
                            width="150"
                          ></el-table-column>
                          <el-table-column
                            prop="mobile_no"
                            label="Mobile No"
                            :show-overflow-tooltip="true"
                            width="120"
                          ></el-table-column>
                          <el-table-column
                            prop="designation"
                            label="Designation"
                            :show-overflow-tooltip="true"
                            width="120"
                          ></el-table-column>
                          <el-table-column
                            prop="gender"
                            label="Gender"
                            :show-overflow-tooltip="true"
                            width="100"
                          ></el-table-column>
                          <el-table-column
                            prop="age"
                            label="Age"
                            :show-overflow-tooltip="true"
                            width="100"
                          ></el-table-column>
                          <el-table-column
                            prop="email_verified"
                            label="Email Verified"
                            :show-overflow-tooltip="true"
                            align="center"
                            width="150"
                          >
                            <template slot-scope="scope">
                              {{
                                colFormatYesNo(
                                  "",
                                  "",
                                  !empty(scope.row.email_verified_at)
                                )
                              }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="status"
                            label="Status"
                            width="100"
                          ></el-table-column>
                          <el-table-column fixed="right" label="..." width="80">
                            <template slot-scope="scope">
                              <v-icon
                                style="cursor: hand"
                                color="success"
                                class="mr-1"
                                @click="viewUser(scope.row)"
                              >
                                mdi-eye
                              </v-icon>
                              <v-icon
                                style="cursor: hand"
                                color="error"
                                @click="resetUser(scope.row)"
                              >
                                mdi-lock-reset
                              </v-icon>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </el-form>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-6 col-sm-12"></div>
      </div>
      <v-overlay :absolute="false" :value="loading">
        <v-progress-circular
          :size="200"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <new-user-form
        :show="showNewUserForm"
        :data="selectedUser"
        :event="formEvent"
        @updated="userUpdated"
        @created="userCreated"
        @close="
          selectedUser = null;
          showNewUserForm = false;
        "
      />
    </v-container>
  </div>
</template>
<script>
import Vue from "vue";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  FormItem,
  Input,
  InputNumber,
  Option,
  Select,
  TimePicker,
} from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Checkbox);
Vue.use(InputNumber);
import { Table, TableColumn } from "element-ui";
Vue.use(Table);
Vue.use(TableColumn);
import ImageField from "@/components/custom/ImageField.vue";
import htmlEditor from "@/components/custom/htmlEditor.vue";
import newUserForm from "./newUserForm.vue";
import Swal from "sweetalert2";
import SelectList from "@/components/custom/SelectList.vue";
export default {
  created() {
    this.getEvent();
  },
  watch: {},
  props: {},
  newd() {},
  components: {
    ImageField,
    htmlEditor,
    newUserForm,
    SelectList,
  },
  data() {
    return {
      formEvent: {},
      view: "view",
      event: {},
      registered: false,
      ageRules: [
        (v) => !!v || "Age is required",
        (v) => v >= 16 || "You should be above 16 years",
      ],
      genders: [
        { id: "Male", name: "Male" },
        { id: "Female", name: "Female" },
      ],
      rules: {
        name: [
          {
            required: true,
            message: "Please enter Event Name",
            trigger: "blur",
          },
        ],
        logo_path: [
          {
            required: true,
            message: "Please select Event Logo",
            trigger: "blur",
          },
        ],
        events_id: [
          { required: true, message: "Please select Client", trigger: "blur" },
        ],
        address: [
          { required: true, message: "Please select Client", trigger: "blur" },
        ],
        tel: [
          { required: true, message: "Please select Client", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "Please select Client", trigger: "blur" },
        ],
        email: [
          { required: true, message: "Please select Client", trigger: "blur" },
        ],
        password: [
          { required: true, message: "Please select Client", trigger: "blur" },
        ],
        contact_person: [
          { required: true, message: "Please select Client", trigger: "blur" },
        ],
        designation: [
          { required: true, message: "Please select Client", trigger: "blur" },
        ],
        contact_no: [
          { required: true, message: "Please select Client", trigger: "blur" },
        ],
        contact_email: [
          { required: true, message: "Please select Client", trigger: "blur" },
        ],
        age: [{ required: true, message: "Please enter Age", trigger: "blur" }],
        gender: [
          { required: true, message: "Please select Gender", trigger: "blur" },
        ],
      },
      selectedUser: null,
      showNewUserForm: false,
      loading: false,
      exhibitor_users_id: null,
      users: [],
    };
  },
  methods: {
    saveForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      this.loading = true;
      this.api("register-exhibitor", this.form)
        .then((response) => {
          if (response.status == true) {
            this.loading = false;
            this.registered = true;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newUser() {
      this.selectedUser = null;
      this.showNewUserForm = true;
    },
    viewUser(user) {
      this.selectedUser = user;
      this.showNewUserForm = true;
    },
    resetUser(user) {
      Swal.fire({
        title: "Reset Password",
        text: "Are you sure you want to reset the User Password, the User will receive an email",
        type: "warning",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: "#5daf34",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        cancelButtonText: "NO",
        confirmButtonText: "YES",
      }).then((result) => {
        if (result.value) {
          this.resetPassword(user);
        }
      });
    },
    resetPassword(user) {
      this.loading = true;
      this.api("reset-password", user, this.exhibitorApi)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.showInfo("User Password changed successfully");
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    back_to_profile() {
      document.location.href = "/exhibitor";
    },
    getEvent() {
      let f = {
        id: this.$route.params.id,
      };
      this.loading = true;
      this.api("get-event-details", f, this.exhibitorApi)
        .then((response) => {
          this.loading = false;
          this.formEvent = response.data;
          this.users = response.data.users;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    userUpdated(user) {
      let i = this.arrayGetIndex(this.formEvent.event_users, this.selectedUser);
      if (i >= 0) {
        this.formEvent.event_users[i] = user;
      }
    },
    userCreated(user) {
      this.formEvent.event_users.push(user);
    },
    addUserFromList(item) {
      if (!this.empty(item)) {
        console.log(this.formEvent);
        let f = {
          exhibitor_users_id: item.id,
          exhibitors_id: this.formEvent.exhibitors_id,
          events_id: this.formEvent.events_id,
        };
        this.loading = true;
        this.api("add-event-user", f, this.exhibitorApi)
          .then((response) => {
            this.loading = false;
            if (response.status == true) {
              let i = this.arrayGetIndex(this.users, item);
              if (i >= 0) {
                this.users.splice(i, 1);
              }
              this.exhibitor_users_id = null;
              this.formEvent.event_users.push(item);
            } else {
              this.showError(response.error);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  name: "EhibitorForm",
};
</script>
<style>
.el-form-item {
  text-align: left;
}
</style>
