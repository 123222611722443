<template>
  <div id="print" ref="print">
    <v-container class="text-center pa-3">
      <v-card
        :loading="loading"
        class="mx-auto"
        :max-width="registered == true ? 420 : 800"
      >
        <v-system-bar height="47" color="primary">
          <h3 class="white--text">REGISTER EXHIBITORS</h3>
          <v-spacer></v-spacer>
        </v-system-bar>
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-img
          crossorigin="anonymous"
          v-if="!empty(event)"
          contain
          height="200"
          :src="event.logo_byte"
          id="event_logo"
          ref="event_logo"
        ></v-img>
        <v-card-title v-if="!empty(event)">{{ event.name }}</v-card-title>
        <v-card-text v-if="!empty(event)">
          <div>
            {{ event.intro_text }}
          </div>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <template v-if="!registered">
          <v-card-title v-if="!empty(event)" class="text-center">
            <div style="width: 100%">
              {{ formatDate(event.from_date, "MMM DD, yyyy") }}
              {{ " to " }}
              {{ formatDate(event.to_date, "MMM DD, yyyy") }}
            </div>
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="pa-2">
            <div class="col-lg-12 col-sm-12 text-center">
              <v-radio-group v-model="registration_type" :column="false">
                <v-radio
                  key="new"
                  label="New Exhibitor"
                  value="new"
                  class="mr-2"
                ></v-radio>
                <v-radio
                  key="existing"
                  label="Already have an Account"
                  value="existing"
                ></v-radio>
              </v-radio-group>
            </div>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="pa-2" v-if="registration_type == 'existing'">
            <v-btn
              title
              color="primary"
              class="mr-0"
              elevation="0"
              @click="login"
            >
              LOGIN TO ADD THIS EVENT
              <v-icon color="white">mdi-key-outline</v-icon>
            </v-btn>
          </v-card-text>
          <v-card-text class="pa-2" v-if="registration_type == 'new'">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              label-width="120px"
              label-position="top"
            >
              <v-card
                :loading="loading"
                class="mx-auto ma-2"
                :elevation="0"
                outlined
              >
                <v-system-bar height="47" color="secondary">
                  <h3 class="white--text">EXHIBITOR DETAILS</h3>
                  <v-spacer></v-spacer>
                </v-system-bar>
                <v-card-text>
                  <div class="row hidden-md-and-up">
                    <div
                      class="col-lg-12 col-sm-12 col-xs-12"
                      style="height: 200px"
                    >
                      <el-form-item label="Logo" prop="logo_path">
                        <upload-field
                          size="mini"
                          folder="exhibitors_logo"
                          allowedFileTypes="['image/jpeg', 'image/png', 'image/bmp']"
                          :width="180"
                          :height="180"
                          v-model="form.logo_path"
                        />
                      </el-form-item>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-sm-12">
                      <div class="row">
                        <div
                          class="
                            col-lg-3 col-sm-12 col-xs-12
                            hidden-sm-and-down
                          "
                        >
                          <el-form-item label="Logo" prop="logo_path">
                            <upload-field
                              size="mini"
                              folder="exhibitors_logo"
                              allowedFileTypes="['image/jpeg', 'image/png', 'image/bmp']"
                              :width="180"
                              :height="180"
                              v-model="form.logo_path"
                            />
                          </el-form-item>
                        </div>
                        <div class="col-lg-9 col-sm-12 col-xs-12">
                          <div class="row">
                            <div class="col-lg-6 col-sm-12">
                              <el-form-item label="English Name" prop="name">
                                <el-input
                                  size="mini"
                                  v-model="form.name"
                                ></el-input>
                              </el-form-item>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                              <el-form-item label="Password" prop="password">
                                <el-input
                                  size="mini"
                                  show-password
                                  v-model="form.password"
                                ></el-input>
                              </el-form-item>
                            </div>
                            <div class="col-lg-12 col-sm-12">
                              <el-form-item label="Address" prop="address">
                                <el-input
                                  size="mini"
                                  type="textarea"
                                  :rows="4"
                                  v-model="form.address"
                                ></el-input>
                              </el-form-item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <el-form-item label="Tel" prop="tel">
                        <el-input size="mini" v-model="form.tel"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <el-form-item label="Mobile" prop="mobile">
                        <el-input size="mini" v-model="form.mobile"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                      <el-form-item label="Email" prop="email">
                        <el-input size="mini" v-model="form.email"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-sm-12">
                      <el-form-item label="Profile" prop="profile">
                        <html-editor v-model="form.profile" />
                      </el-form-item>
                    </div>
                    <div
                      class="col-lg-12 col-sm-12 col-xs-12"
                      style="height: 230px"
                    >
                      <el-form-item label="Image" prop="image_path">
                        <upload-field
                          size="mini"
                          folder="exhibitor_images"
                          allowedFileTypes="['image/jpeg', 'image/png', 'image/bmp']"
                          :height="180"
                          v-model="form.image_path"
                        />
                      </el-form-item>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
              <v-card
                :loading="loading"
                class="mx-auto ma-2"
                :elevation="0"
                outlined
              >
                <v-system-bar height="47" color="secondary">
                  <h3 class="white--text">CONTACT PERSON</h3>
                  <v-spacer></v-spacer>
                </v-system-bar>
                <v-card-text>
                  <div class="row">
                    <div class="col-lg-6 col-sm-12">
                      <el-form-item
                        label="Contact Person Name"
                        prop="contact_person"
                      >
                        <el-input
                          size="mini"
                          v-model="form.contact_person"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                      <el-form-item label="Designation" prop="designation">
                        <el-input
                          size="mini"
                          v-model="form.designation"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <el-form-item label="Contact No" prop="contact_no">
                        <el-input
                          size="mini"
                          v-model="form.contact_no"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                      <el-form-item label="Email Address" prop="contact_email">
                        <el-input
                          size="mini"
                          v-model="form.contact_email"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                      <el-form-item label="Gender" prop="gender">
                        <v-radio-group v-model="form.gender" :column="false">
                          <v-radio
                            v-for="v in genders"
                            :key="v.id"
                            :label="v.name"
                            :value="v.id"
                          ></v-radio>
                        </v-radio-group>
                      </el-form-item>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                      <el-form-item label="Age" prop="age">
                        <v-row>
                          <v-col cols="10" sm="10">
                            <v-slider
                              v-model="form.age"
                              :rules="ageRules"
                              color="orange"
                              min="1"
                              max="100"
                              thumb-label
                            ></v-slider>
                          </v-col>
                          <v-col cols="2" sm="2">
                            <div class="v-text-field__slot">
                              <input
                                disabled="disabled"
                                style="margin-top: -10px"
                                id="input-45"
                                type="text"
                                v-model="form.age"
                              />
                            </div>
                          </v-col>
                        </v-row>
                      </el-form-item>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
              <v-card
                :loading="loading"
                class="mx-auto ma-2"
                :elevation="0"
                outlined
              >
                <v-system-bar height="47" color="secondary">
                  <h3 class="white--text">STALL</h3>
                  <v-spacer></v-spacer>
                </v-system-bar>
                <v-card-text>
                  <div class="row">
                    <div class="col-lg-6 col-sm-12">
                      <el-form-item label="Requested Stall" prop="stall_no">
                        <el-input
                          size="mini"
                          v-model="form.stall_no"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
              <v-card
                :loading="loading"
                class="mx-auto ma-2"
                :elevation="0"
                outlined
              >
                <v-card-text>
                  <div class="row">
                    <div class="col-lg-6 col-sm-12">
                      <v-btn
                        title
                        color="primary"
                        class="mr-0"
                        elevation="0"
                        @click="saveForm"
                      >
                        REGISTER FOR AN EXHIBITOR
                        <v-icon color="white">mdi-content-save-outline</v-icon>
                      </v-btn>
                    </div>
                    <div class="col-lg-6 col-sm-12" style="align: right">
                      <v-btn
                        title
                        color="warning"
                        class="mr-0"
                        elevation="0"
                        @click="back_to_events"
                      >
                        BACK TO EVENTS
                        <v-icon color="white">mdi-exit-to-app</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </el-form>
          </v-card-text>
        </template>
        <template v-else>
          <div class="row">
            <div class="col-12">
              <v-card-title class="pa-3">
                You request has been received, we will respond soon.
              </v-card-title>
              <v-card-subtitle>
                An Email has been sent to your provided email address, please
                click on the email to verify.
              </v-card-subtitle>
            </div>
          </div>
        </template>
      </v-card>
      <v-overlay :absolute="false" :value="loading">
        <v-progress-circular
          :size="200"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </v-container>
  </div>
</template>
<script>
import Vue from "vue";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  FormItem,
  Input,
  InputNumber,
  Option,
  Select,
  TimePicker,
} from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Checkbox);
Vue.use(InputNumber);
import UploadField from "@/components/custom/UploadField.vue";
import htmlEditor from "@/components/custom/htmlEditor.vue";
export default {
  created() {
    this.getEvent();
  },
  watch: {},
  props: {},
  newd() {},
  components: {
    UploadField,
    htmlEditor,
  },
  data() {
    return {
      form: {},
      view: "view",
      registration_type: "new",
      event: {
        logo_path: null,
        name: "Event",
        intro_text: "intreo",
      },
      registered: false,
      ageRules: [
        (v) => !!v || "Age is required",
        (v) => v >= 16 || "You should be above 16 years",
      ],
      genders: [
        { id: "Male", name: "Male" },
        { id: "Female", name: "Female" },
      ],
      rules: {
        name: [
          {
            required: true,
            message: "Please enter Event Name",
            trigger: "blur",
          },
        ],
        logo_path: [
          {
            required: true,
            message: "Please select Event Logo",
            trigger: "blur",
          },
        ],
        events_id: [
          { required: true, message: "Please select Event", trigger: "blur" },
        ],
        address: [
          { required: true, message: "Please enter Address", trigger: "blur" },
        ],
        tel: [{ required: true, message: "Please enter Tel", trigger: "blur" }],
        mobile: [
          { required: true, message: "Please enter Mobile", trigger: "blur" },
        ],
        email: [
          { required: true, message: "Please enter Email", trigger: "blur" },
        ],
        password: [
          { required: true, message: "Please enter Password", trigger: "blur" },
        ],
        contact_person: [
          {
            required: true,
            message: "Please enter Contact Person",
            trigger: "blur",
          },
        ],
        designation: [
          {
            required: true,
            message: "Please enter Designation",
            trigger: "blur",
          },
        ],
        contact_no: [
          {
            required: true,
            message: "Please enter Contact No",
            trigger: "blur",
          },
        ],
        contact_email: [
          {
            required: true,
            message: "Please enter Contact Person Email",
            trigger: "blur",
          },
        ],
        age: [{ required: true, message: "Please enter Age", trigger: "blur" }],
        gender: [
          { required: true, message: "Please select Gender", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },
  methods: {
    saveForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      this.loading = true;
      this.api("register-exhibitor", this.form)
        .then((response) => {
          if (response.status == true) {
            this.loading = false;
            this.registered = true;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    back_to_events() {
      document.location.href = "/";
    },
    login() {
      document.location.href = "/sign-in?events_id=" + this.form.events_id;
    },
    newForm() {
      this.form = {
        id: null,
        events_id: !this.empty(this.event) ? this.event.id : null,
        name: null,
        logo_path: null,
        logo_byte: null,
        address: null,
        tel: null,
        mobile: null,
        email: null,
        email_verified_at: null,
        password: null,
        contact_person: null,
        designation: null,
        contact_no: null,
        contact_email: null,
        change_password: null,
        stall_no: null,
        profile: null,
        image_path: null,
        image_byte: null,
        intro_text: null,
        from_date: null,
        to_date: null,
        age: null,
        gender: null,
        status: "Active",
      };
      this.view = "new";
    },
    getEvent() {
      let f = {
        codes_id: this.$route.params.id,
        slug: this.$route.params.slug,
      };
      this.loading = true;
      this.api("rsvp", f)
        .then((response) => {
          this.loading = false;
          this.event = response.data;
          this.newForm();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "EhibitorForm",
};
</script>
<style>
.el-form-item {
  text-align: left;
}
</style>
