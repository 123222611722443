<template>
  <div id="print" ref="print">
    <v-container class="text-center pa-0">
      <div class="row">
        <div class="col-12 col-sm-12">
          <v-card :loading="loading" class="mx-auto">
            <v-system-bar height="47" color="primary">
              <h3 class="white--text">EVENTS</h3>
              <v-spacer></v-spacer>
            </v-system-bar>
            <v-card-text class="pa-2">
              <el-table :data="events" style="width: 100%">
                <el-table-column width="150" prop="logo_path" label="Logo">
                  <template slot-scope="scope">
                    <image-field
                      size="mini"
                      :disabled="true"
                      :show-zoom="false"
                      :image-click="true"
                      width="0"
                      height="50"
                      :value="
                        !empty(scope.row.logo_path) ? scope.row.logo_path : null
                      "
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="Event Name"
                ></el-table-column>
                <el-table-column
                  prop="event_type"
                  label="Event Type"
                  width="150"
                ></el-table-column>
                <el-table-column
                  prop="from_date"
                  label="From Date"
                  :formatter="colFormatDate"
                  width="120"
                ></el-table-column>
                <el-table-column
                  prop="to_date"
                  label="To Date"
                  :formatter="colFormatDate"
                  width="120"
                ></el-table-column>
                <el-table-column
                  prop="start_time"
                  label="Start Time"
                  :formatter="colFormatTime"
                  width="100"
                ></el-table-column>
                <el-table-column
                  prop="end_time"
                  label="End Time"
                  :formatter="colFormatTime"
                  width="100"
                ></el-table-column>
                <el-table-column
                  prop="free_event"
                  label="Free Event"
                  :formatter="colFormatYesNo"
                  align="center"
                  width="100"
                ></el-table-column>
                <el-table-column
                  prop="status"
                  label="Status"
                  width="100"
                ></el-table-column>
                <el-table-column fixed="right" label="..." width="40">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      @click="viewRecord(scope.row)"
                    >
                      View
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-6 col-sm-12"></div>
      </div>
      <v-overlay :absolute="false" :value="loading">
        <v-progress-circular
          :size="200"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </v-container>
  </div>
</template>
<script>
import Vue from "vue";
import { Table, TableColumn } from "element-ui";
Vue.use(Table);
Vue.use(TableColumn);
import ImageField from "@/components/custom/ImageField.vue";
export default {
  created() {
    this.getEvents();
  },
  watch: {},
  props: {},
  newd() {},
  components: {
    ImageField,
  },
  data() {
    return {
      events: [],
      loading: false,
    };
  },
  methods: {
    getEvents() {
      this.loading = true;
      this.api("get-events", null, this.exhibitorApi)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.events = response.data;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    viewRecord(row) {
      document.location.href = "exhibitor/event/view/" + row.id;
    },
  },
  name: "EhibitorEventsList",
};
</script>
<style>
.el-form-item {
  text-align: left;
}
</style>
