<template>
  <div>
    <el-select
      v-model="selectedValue"
      styly="width: 100%"
      :disabled="disabled"
      @focus="load_component()"
      size="mini"
      filterable
      :multiple="multiple"
      clearable
      :placeholder="
        !empty(placeholder)
          ? placeholder
          : getTitle(!empty(editor) ? editor : customUrl)
      "
      @change="changed"
      :name="!empty(editor) ? editor : customUrl"
    >
      <el-option
        v-for="item in listdata"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      ></el-option>
      <!--<el-divider style="margin: 0 !important"></el-divider> -->
      <el-option
        v-if="!disabled && !readonly && !multiple"
        key=""
        label=""
        value=""
        class="create-item"
      >
        <template>
          <el-button
            @click.native.prevent="show_create_screen"
            v-if="allownew && !empty(editor)"
            type="text"
            size="mini"
          >
            CREATE
            {{ getTitle(editor).toUpperCase() }}
          </el-button>
        </template>
      </el-option>
      <!--<template v-slot:empty>
        <el-button
          @click.native.prevent="show_create_screen"
          v-if="allownew"
          type="text"
          size="mini"
          style="padding: 10px; width: 100%; text-align: left"
        >
          Create {{ getTitle(editor) }}
        </el-button>
      </template>-->
    </el-select>
    <comp
      v-if="show && !empty(editor)"
      :show="showForm"
      :apiUrl="editor"
      :end_point="editor + '/'"
      :title="getTitle(!empty(editor) ? editor : customUrl)"
      api="create"
      v-on:close="close"
      :perm="permissions"
      v-on:created="created"
      :popup="true"
    ></comp>
  </div>
</template>
<script>
import Vue from "vue";
import { Divider } from "element-ui";
Vue.use(Divider);
export default {
  name: "SelectListControl",
  components: {},
  props: {
    value: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: null,
    },
    editor: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    customUrl: {
      type: String,
      default: "",
    },
    extraid: null,
    extraid1: null,
    extraid2: null,
    filtered: {
      type: Boolean,
      default: false,
    },
    view: null,
  },
  watch: {
    selectedValue: function () {
      this.$emit("input", this.selectedValue);
      //this.$emit("change");
    },
    list: function () {
      if (!this.empty(this.list)) {
        this.listdata = this.list;
      }
    },
    value: function () {
      this.selectedValue = this.value;
    },
    extraid: function () {
      if (!this.disabled) this.selectedValue = null;
      this.reload_list();
    },
    extraid1: function () {
      if (!this.disabled) this.selectedValue = null;
      this.reload_list();
    },
    extraid2: function () {
      if (!this.disabled) this.selectedValue = null;
      this.reload_list();
    },
  },
  data: function () {
    return {
      selectedValue: null,
      show: false,
      showForm: false,
      listdata: [],
      permissions: [],
    };
  },
  mounted() {
    this.reload_list();
  },
  methods: {
    load_component() {
      if (this.disabled || this.readonly) return;
      let c = this.getComponent();
      if (this.empty(c)) return;
      this.$options.components["comp"] = () => import("@/components/" + c);
      this.show = true;
    },
    getTitle(value) {
      if (this.empty(value)) {
        console.log(value);
        return "";
      }
      let r = value.split("/");
      if (r.length > 2) {
        return "";
      }
      return this.toTitleCase(r[2]);
    },
    show_create_screen() {
      if (this.showForm) {
        this.showForm = false;
        this.$nextTick(() => (this.showForm = true));
      } else {
        this.showForm = true;
      }
    },
    getComponent() {
      let r = this.editor.split("/");
      if (this.empty(r) || r.length < 3) return;
      return (
        r[0].replace("-", "").replace("-", "") +
        "/" +
        r[1].replace("-", "").replace("-", "") +
        "/" +
        r[2].replace("-", "").replace("-", "") +
        "/" +
        "form.vue"
      );
    },
    created() {
      //this.reload_list();
    },
    reload_list() {
      if (!this.empty(this.list)) {
        this.listdata = this.list;
        return;
      }
      if (this.empty(this.editor) && this.empty(this.customUrl)) return;
      let f = {
        extraid: this.extraid,
        extraid1: this.extraid1,
        extraid2: this.extraid2,
      };
      let self = this;
      let api = this.editor + "/list";
      if (!this.empty(this.customUrl)) {
        api = this.customUrl;
      }
      this.api(api, f)
        .then((response) => {
          this.listdata = response.data;
          this.permissions = response.permissions;
          this.showForm = false;
          if (!this.empty(this.value))
            if (!this.multiple) {
              this.selectedValue = parseInt(this.value);
            }
        })
        .catch(function (error) {
          if (error.response) {
            self.showError(error.response, "Select List Control");
          } else {
            self.error(
              "Unknown errror, Contact System Administrator",
              "Select List Control"
            );
          }
        });
    },
    changed() {
      let sel = this.listdata.filter((e) => e.id == this.selectedValue);
      if (sel.length > 0) sel = sel[0];
      this.$emit("change", sel);
    },
    close() {
      this.reload_list();
      this.showForm = false;
    },
  },
  computed: {
    allownew() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Create").length > 0
        : false;
    },
  },
};
</script>
<style scoped>
.is-empty {
  height: 0px !important;
}
.row {
  margin: 0px !important;
}
.el-select {
  width: 100%;
}
.create-item {
  border-top: 1px solid #747474;
  background: lightgrey;
}
.el-button--text {
  color: black !important;
  font-weight: bold !important;
}
</style>
<style>
.el-select-dropdown__list {
  padding: 6px 0 0 0 !important;
}
</style>
