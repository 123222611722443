<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      hide-overlay
      width="800px"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-container class="pa-0">
        <v-card class="pb-10">
          <v-system-bar
            height="47"
            :color="
              !empty(form) &&
              !empty(form.status) &&
              (form.status == 'Active' ||
                form.status == 'Approved' ||
                form.status == 'Confirmed' ||
                form.status.includes('Approved'))
                ? '#aeddff'
                : !empty(form) &&
                  !empty(form.status) &&
                  form.status == 'Pending'
                ? '#fc9303'
                : '#ff5252'
            "
          >
            <h3
              :class="
                !empty(form) &&
                !empty(form.status) &&
                (form.status == 'Active' ||
                  form.status == 'Approved' ||
                  form.status == 'Confirmed' ||
                  form.status.includes('Approved'))
                  ? 'black--text'
                  : 'white--text'
              "
            >
              {{ title.toUpperCase() }}
            </h3>
            <span
              class="ml-3 mt-0 white--text blink"
              v-if="
                !empty(form) &&
                !empty(form.status) &&
                (form.status == 'Disabled' ||
                  form.status == 'Rejected' ||
                  form.status == 'Cancelled')
              "
            >
              - RECORD IS DISABLED
            </span>
            <v-spacer></v-spacer>
            <template>
              <v-btn
                title
                :color="
                  !empty(form) &&
                  !empty(form.status) &&
                  (form.status == 'Disabled' ||
                    form.status == 'Rejected' ||
                    form.status == 'Cancelled')
                    ? 'primary'
                    : 'error'
                "
                v-if="view == 'view'"
                class="mr-2"
                @click="close"
                elevation="0"
              >
                <v-icon color="white">mdi-close</v-icon>
                CLOSE
              </v-btn>
              <v-btn
                title
                color="primary"
                @click="newForm"
                class="mr-2 hidden-sm-and-down"
                v-if="view == 'view'"
                elevation="0"
              >
                <v-icon color="white">mdi-plus</v-icon>
                NEW
              </v-btn>
              <v-btn
                title
                color="primary"
                v-if="
                  !empty(form.id) && view == 'view' && form.status != 'Disabled'
                "
                @click="view = 'edit'"
                class="hidden-sm-and-down"
                elevation="0"
              >
                <v-icon color="white">mdi-pencil</v-icon>
                EDIT
              </v-btn>
              <v-btn
                title
                color="error"
                v-if="view != 'view'"
                @click="cancelForm"
                class="hidden-sm-and-down"
                elevation="0"
              >
                <v-icon color="white">mdi-cancel</v-icon>
                CANCEL
              </v-btn>
              <v-btn
                title
                color="success"
                class="ml-2 hidden-sm-and-down"
                v-if="view == 'new' || view == 'edit'"
                @click="saveForm"
                elevation="0"
              >
                <v-icon color="white">mdi-content-save</v-icon>
                SAVE
              </v-btn>
              <v-btn
                title
                color="error"
                class="ml-2 hidden-sm-and-down"
                v-if="
                  !empty(form.id) && form.status == 'Active' && view == 'view'
                "
                @click="disableRecord(false)"
                elevation="0"
              >
                <v-icon color="white">mdi-close-circle</v-icon>
                DISABLE
              </v-btn>
              <v-btn
                title
                color="success"
                class="ml-2 hidden-sm-and-down"
                v-if="
                  !empty(form.id) && form.status == 'Disabled' && view == 'view'
                "
                @click="disableRecord(true)"
                elevation="0"
              >
                <v-icon color="white">mdi-close-circle</v-icon>
                ENABLE
              </v-btn>
            </template>
          </v-system-bar>
          <v-divider />
          <v-card-text>
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              label-width="120px"
              label-position="top"
            >
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Contact Person Name" prop="name">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.name"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Designation" prop="designation">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.designation"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Mobile No" prop="mobile_no">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.mobile_no"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Email" prop="email">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.email"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12"></div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Gender" prop="gender">
                    <v-radio-group v-model="form.gender" :column="false">
                      <v-radio
                        v-for="v in genders"
                        :key="v.id"
                        :label="v.name"
                        :value="v.id"
                      ></v-radio>
                    </v-radio-group>
                  </el-form-item>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <el-form-item label="Age" prop="age">
                    <v-row>
                      <v-col cols="10" sm="10">
                        <v-slider
                          v-model="form.age"
                          :rules="ageRules"
                          color="orange"
                          min="1"
                          max="100"
                          thumb-label
                        ></v-slider>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="v-text-field__slot">
                          <input
                            disabled="disabled"
                            style="margin-top: -10px"
                            id="input-45"
                            type="text"
                            v-model="form.age"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Address" prop="address">
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="4"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.address"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12"></div>
                <div class="col-lg-6 col-sm-12" v-if="view == 'view'">
                  <el-form-item label="Status" prop="status">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.status"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </v-card-text>
          <!--<v-divider />-->
        </v-card>
        <v-overlay :value="loading" :opacity="0.7">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-dialog>
  </v-row>
</template>
<script>
import Vue from "vue";
import {
  Button,
  Checkbox,
  Form,
  FormItem,
  Input,
  Option,
  Select,
} from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
export default {
  watch: {
    show: function () {
      this.showForm = this.show;
      this.permissions = this.perm;
      if (this.showForm) {
        if (this.empty(this.data)) {
          this.newForm();
        } else {
          this.view = "view";
        }
      }
    },
    data: function () {
      if (!this.empty(this.data)) {
        this.form = this.data;
        console.log(this.form);
        this.view = "view";
        console.log(this.view);
      }
    },
    perm: function () {
      this.permissions = this.perm;
    },
  },
  props: {
    data: null,
    event: null,
    show: {
      type: Boolean,
      default: false,
    },
  },
  newd() {},
  components: {},
  data() {
    var numberAboveZero = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("Please input a Number"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("Please input Numeric Digits"));
        } else {
          if (value < 16) {
            callback(new Error("Must be of at least 16 years of Age"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    return {
      form: {},
      view: "view",
      ageRules: [
        (v) => !!v || "Age is required",
        (v) => v >= 16 || "You should be above 16 years",
      ],
      rules: {
        name: [
          {
            required: true,
            message: "Please enter Contact Person Name",
            trigger: "blur",
          },
        ],
        designation: [
          {
            required: true,
            message: "Please enter Designation",
            trigger: "blur",
          },
        ],
        mobile_no: [
          {
            required: true,
            message: "Please enter Mobile No",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "Please select Email", trigger: "blur" },
        ],
        gender: [
          { required: true, message: "Please select Gender", trigger: "blur" },
        ],
        age: [
          {
            validator: numberAboveZero,
            trigger: "blur",
          },
        ],
      },
      permissions: [],
      title: "NEW USER",
      loading: false,
      showForm: false,
      genders: [
        { id: "Male", name: "Male" },
        { id: "Female", name: "Female" },
      ],
    };
  },
  methods: {
    saveForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      this.loading = true;
      var api = "create-user";
      if (!this.empty(this.form.id)) api = "update-user";
      this.api(api, this.form, this.exhibitorApi)
        .then((response) => {
          if (response.status == true) {
            this.loading = false;
            this.form = response.data;
            this.view = "view";
            this.showInfo("Record Saved successfully");
            if (api == "create-user") {
              this.$emit("created", this.form);
            } else {
              this.$emit("updated", this.form);
            }
            this.close();
          } else {
            if (
              response.error.includes(
                "Cannot insert duplicate key row in object 'dbo.exhibitor_users'"
              )
            ) {
              this.showError("User already exists");
            } else {
              this.showError(response.error);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    change_status(enable) {
      var api = "enable";
      if (!enable) api = "disable";
      this.loading = true;
      this.api(this.end_point + api, this.form)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newForm() {
      console.log(this.event);
      this.form = {
        id: null,
        exhibitors_id: this.event.exhibitor.id,
        events_id: this.event.id,
        name: null,
        password: null,
        change_password: true,
        email: null,
        last_logged_in: null,
        designation: null,
        company: this.event.exhibitor.name,
        address: null,
        age: null,
        gender: null,
        mobile_no: null,
        status: "Active",
      };
      this.view = "new";
      console.log(this.view);
    },
    getRecord() {
      let f = {
        id: this.data.id,
      };
      this.loading = true;
      this.api(this.end_point + "record", f)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
          this.permissions = response.permissions;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("close");
    },
    cancelForm() {
      this.view = "view";
      if (this.popup) this.close();
      if (this.empty(this.form.id)) this.close();
    },
    viewChanged(val) {
      this.view = val;
    },
  },
  name: "ExhibutorNewUserForm",
};
</script>
